import React from "react";
import LanguageSelect from "./LanguageSelect";

const SixFieldLayout = ({ title, episodeLabel }: any) => {
  return (
    <div>
      <div>
        <p className="ft18 ftw700 text-white">{title}</p>
        <div className="gridContainerStyle">
          <div className="gridItemStyle">
            <p className="contentLabelName">
              Content Title
              <span className="mandatoryField">*</span>
            </p>
            <input className="contentInput" />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">
              Original Language
              <span className="mandatoryField">*</span>
            </p>
            <input className="contentInput" placeholder="Type to add" />
          </div>
          <div className="gridItemStyle">
            {" "}
            <p className="contentLabelName">
              Available Audio Languages
              <span className="mandatoryField">*</span>
            </p>
            <div>
              <LanguageSelect />
            </div>
          </div>
          <div className="gridItemStyle">
            {" "}
            <p className="contentLabelName">
              Available Subtitles
              <span className="mandatoryField">*</span>
            </p>
            <LanguageSelect />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">
              Season
              <span className="mandatoryField">*</span>
            </p>
            <input className="contentInput" placeholder="Type to add" />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">
              {episodeLabel}
              <span className="mandatoryField">*</span>
            </p>
            <input className="contentInput" placeholder="Type to add" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SixFieldLayout;
