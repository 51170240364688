import React from "react";
import { RiDeleteBin5Line } from "react-icons/ri";

const Promotions = () => {
  const projectDetailsMap = [
    {
      id: 1,
      project: "www.awards.com",
    },
    {
      id: 2,
      project: "www.awards.com",
    },
  ];
  return (
    <div>
      <div>
        <p className="text-white ft18 ftw700">Promotions</p>
      </div>

      <div style={{ marginTop: "35px" }}>
        <div className="gridContainerStyle">
          <div className="gridItemStyle">
            <p className="contentLabelName">
              Upload Movie Poster
              <span className="mandatoryField">*</span>
            </p>
            <div
              style={{
                backgroundColor: "#2F144B",
                height: "46px",
                marginTop: "12px",
                borderRadius: "5px",
                display: "flex",
                padding: "0px 2px",
                position: "relative",
              }}
            >
              <input
                type="file"
                className=""
                style={{ color: "white", padding: "10px" }}
              />

              <button className="uploadBtn">Upload</button>
            </div>
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">
              Share the Movie Trailer/Promo Link
              <span className="mandatoryField">*</span>
            </p>
            <input className="contentInput" placeholder="URL" />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">IMDB URL Link</p>
            <input className="contentInput" placeholder="" />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">Wikipedia URL</p>
            <input className="contentInput" placeholder="" />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">EDIR</p>
            <input className="contentInput" placeholder="" />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">Unreleased Link</p>
            <input className="contentInput" placeholder="" />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "25px" }}>
        <p className="contentLabelName">Awards/Media Coverage/Links</p>
        <div style={{ display: "flex", gap: "10px", marginTop: "15px" }}>
          <input
            className="productionHouseName"
            placeholder=""
            style={{ marginTop: "0" }}
          />
          <button className="addButton">Add</button>
        </div>
      </div>

      <div style={{ marginTop: "50px" }}>
        <div className="crewTable headerRow">
          {["S.No", "URL"].map((item: any, index: number) => (
            <p key={index} className="contentLabelName">
              {item}
            </p>
          ))}
        </div>
        {projectDetailsMap.map((item: any) => (
          <div className="crewTableContent" key={item.id}>
            <p className="crewTableValue">{item.id}</p>
            <p
              className="crewTableValue"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {item.project}
              <RiDeleteBin5Line className="crewTableValueIcon" />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Promotions;
