import React from "react";
import LanguageSelect from "./LanguageSelect";
import {
  indianMovieRatingOptions,
  internationalMovieRatingOptions,
} from "../../utils/data";

const Advisory = () => {
  return (
    <div>
      <div>
        <p className="text-white ft18 ftw700">Advisory</p>
      </div>

      <div className="gridContainerStyle">
        <div className="gridItemStyle">
          <p className="contentLabelName">Indian Censor Certificate</p>
          <div
            style={{
              backgroundColor: "#2F144B",
              height: "46px",
              marginTop: "12px",
              borderRadius: "5px",
              display: "flex",
              padding: "0px 2px",
              position: "relative",
            }}
          >
            <input
              type="file"
              className=""
              style={{ color: "white", padding: "10px" }}
            />

            <button className="uploadBtn">Upload</button>
          </div>
        </div>
        <div className="gridItemStyle">
          <p className="contentLabelName">
            Movie Duration in Minutes<span className="mandatoryField">*</span>
          </p>
          <input className="contentInput" placeholder="Type to add" />
        </div>
        <div className="gridItemStyle">
          {" "}
          <p className="contentLabelName">International Movie Film Rating</p>
          <div>
            <LanguageSelect
              isMulti={false}
              options={internationalMovieRatingOptions}
              defaultValue={[internationalMovieRatingOptions[0]]}
            />
          </div>
        </div>
        <div className="gridItemStyle">
          {" "}
          <p className="contentLabelName">Indian Movie Film Rating</p>
          <LanguageSelect
            isMulti={false}
            options={indianMovieRatingOptions}
            defaultValue={[indianMovieRatingOptions[0]]}
          />
        </div>
      </div>

      <div style={{ marginTop: "50px" }}>
        <p className="text-white ft18 ftw700">
          Producer Council Registration Details
        </p>

        <div className="gridContainerStyle">
          <div className="gridItemStyle">
            <p className="contentLabelName">Region</p>
            <input className="contentInput" placeholder="" />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">State</p>
            <input className="contentInput" placeholder="" />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">Council Name</p>
            <input className="contentInput" placeholder="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advisory;
