import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import StepsHandler from "./StepsHandler";

const steps = [
  "Content Type",
  "Synopsis & Genre",
  "Cast & Crew",
  "Advisory",
  "Promotions",
  "Distribution & Release",
  "Rights Available",
  "Producer Information",
];

const SellStructure = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrev = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "25%" }}>
        <div className="leftBox">
          <div style={{ height: "100vh" }}>
            <div>
              <p
                style={{ textAlign: "center" }}
                className="text-white ft24 ftw700"
              >
                Sell Digital Rights
              </p>
            </div>

            <div className="vertical-stepper">
              <ul className="step-list">
                {steps.map((step, index) => (
                  <li
                    key={index}
                    className={`step-item  ${
                      index === activeStep ? "step-item-active" : ""
                    }`}
                  >
                    <span className="step-number">
                      {index === activeStep ? (
                        <FaCheckCircle className="stepActiveIcon" />
                      ) : (
                        ""
                      )}
                    </span>
                    <span className="step-label">{step}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "75%" }}>
        <div className="rightBox">
          <div style={{ height: "200vh" }}>
            <div
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: "5px",
              }}
            >
              <div style={{ height: "190vh" }}>
                <StepsHandler activeStep={activeStep} />
              </div>

              <div className="step-actions">
                <button
                  onClick={handlePrev}
                  disabled={activeStep === 0}
                  className="prevBtn"
                >
                  <FaChevronLeft className="arrowIcon" />
                </button>
                <button
                  onClick={handleNext}
                  disabled={activeStep === steps.length - 1}
                  className="nextBtn"
                >
                  <FaChevronRight className="arrowIcon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellStructure;
