import React, { useState } from "react";
import CustomAccordion from "./CustomAccordion";
import Select from "react-select";
import {
  languageOptions,
  contentTypes,
  customStyles,
  directorOptions,
  genreTypes,
  indianRatingTypes,
  internationalRatingTypes,
  producerOptions,
  rightsTypes,
  starOptions,
  yearOptions,
} from "../../utils/data";
import MultivaluesSelection from "./MultivaluesSelection";
import { RangeSlider } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const FilterItems = () => {
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [rangeValue, setRangeValue] = useState<[number, number]>([60, 120]);

  // Handle range change
  const handleRangeChange = (value: [number, number]) => {
    setRangeValue(value);
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
  };
  return (
    <aside>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"Language"}>
              <Select
                defaultValue={[languageOptions[0]]}
                isMulti
                name="colors"
                options={languageOptions}
                className="basic-multi-select"
                classNamePrefix="languageSelect"
                styles={customStyles}
                placeholder="enter a language"
              />
            </CustomAccordion>
          </div>
        </div>
      </div>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"Content Type"}>
              <MultivaluesSelection data={contentTypes} />
            </CustomAccordion>
          </div>
        </div>
      </div>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"Genre"}>
              <MultivaluesSelection data={genreTypes} />
            </CustomAccordion>
          </div>
        </div>
      </div>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"Producer"}>
              <Select
                defaultValue={[producerOptions[0]]}
                isMulti
                name="colors"
                options={producerOptions}
                className="basic-multi-select"
                classNamePrefix="languageSelect"
                styles={customStyles}
                placeholder="enter producer name"
              />
            </CustomAccordion>
          </div>
        </div>
      </div>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"Director"}>
              <Select
                defaultValue={[directorOptions[0]]}
                isMulti
                name="colors"
                options={directorOptions}
                className="basic-multi-select"
                classNamePrefix="languageSelect"
                styles={customStyles}
                placeholder="enter director name"
              />
            </CustomAccordion>
          </div>
        </div>
      </div>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"Starring"}>
              <Select
                defaultValue={[starOptions[0]]}
                isMulti
                name="colors"
                options={starOptions}
                className="basic-multi-select"
                classNamePrefix="languageSelect"
                styles={customStyles}
                placeholder="enter actors name"
              />
            </CustomAccordion>
          </div>
        </div>
      </div>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"Indian Rating"}>
              <MultivaluesSelection data={indianRatingTypes} />
            </CustomAccordion>
          </div>
        </div>
      </div>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"Duration in Minutes"}>
              <div>
                <RangeSlider
                  defaultValue={[60, 120]}
                  value={rangeValue}
                  onChange={handleRangeChange}
                  min={0}
                  max={180}
                  step={1}
                  graduated
                  progress
                  style={{ marginBottom: 10 }}
                />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="spanMin">{rangeValue[0]}</span>
                  <span className="spanMin">{rangeValue[1]}</span>
                </div>
              </div>
            </CustomAccordion>
          </div>
        </div>
      </div>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"International Rating"}>
              <MultivaluesSelection data={internationalRatingTypes} />
            </CustomAccordion>
          </div>
        </div>
      </div>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"Rights"}>
              <MultivaluesSelection data={rightsTypes} />
            </CustomAccordion>
          </div>
        </div>
      </div>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"Released Status"}>
              <div style={{ display: "flex", gap: "25px" }}>
                {["Yes", "No"].map((item: any) => (
                  <div
                    key={item}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="radio"
                      name="myRadio"
                      value={item}
                      className="custom-radio"
                      id={item}
                      onChange={handleRadioChange}
                      checked={selectedValue === item}
                    />
                    <label htmlFor={item} className="releaseLabel">
                      {item}
                    </label>
                  </div>
                ))}
              </div>
            </CustomAccordion>
          </div>
        </div>
      </div>
      <div className="filterItems">
        <div style={{ background: "#2F144B", borderRadius: "10px" }}>
          <div style={{ padding: "15px" }}>
            <CustomAccordion title={"Year of Release"}>
              <Select
                defaultValue={[yearOptions[0]]}
                isMulti
                name="colors"
                options={yearOptions}
                className="basic-multi-select"
                classNamePrefix="languageSelect"
                styles={customStyles}
                placeholder="enter actors name"
              />
            </CustomAccordion>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default FilterItems;
