import React from "react";
import ContentType from "./ContentType";
import SynopsisGenre from "./SynopsisGenre";
import CastCrew from "./CastCrew";
import Advisory from "./Advisory";
import Promotions from "./Promotions";
import DistributionRelease from "./DistributionRelease";
import RightsAvailable from "./RightsAvailable";
import ProducerInformation from "./ProducerInformation";

const StepsHandler = ({ activeStep }: any) => {
  const stepHandler = () => {
    switch (activeStep) {
      case 0:
        return <ContentType />;
      case 1:
        return <SynopsisGenre />;
      case 2:
        return <CastCrew />;
      case 3:
        return <Advisory />;
      case 4:
        return <Promotions />;
      case 5:
        return <DistributionRelease />;
      case 6:
        return <RightsAvailable />;
      case 7:
        return <ProducerInformation />;
      default:
        return null;
    }
  };
  return <div>{stepHandler()}</div>;
};

export default StepsHandler;
