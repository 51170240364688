import React from "react";
import { socialLogin } from "../../utils/data";

const Login = () => {
  return (
    <div>
      <div>
        <p className="signInText fontPoppins">SIGN IN</p>
        <p className="loginSubtext fontPoppins">Login to access your account</p>
      </div>
      <form className="loginForm">
        <aside>
          <p className="loginLabel fontPoppins">Email</p>
          <input
            type="email"
            placeholder="johndoe@gmail.com"
            className="fontPoppins"
          />
        </aside>
        <aside>
          <p className="loginLabel fontPoppins">Password</p>
          <input
            type="password"
            placeholder="password"
            className="fontPoppins"
          />
        </aside>

        <p className="forgotPass fontPoppins">Forgot Password</p>

        <button className="">LOGIN</button>

        <aside style={{ width: "100%", display: "flex", gap: "6px" }}>
          {socialLogin.map((item: any) => (
            <div className="loginSocial" key={item.id}>
              <img src={item.pic} alt="icon" />
            </div>
          ))}
        </aside>

        <p className="fontPoppins accSignUp ft16 ftw400">
          Don’t have an account? <span>Sign Up</span>
        </p>
      </form>
    </div>
  );
};

export default Login;
