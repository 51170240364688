import React from "react";
import tempPic from "../../assets/tempHeroBanner.png";

const HeroBanner = () => {
  return (
    <section>
      <div className="herobannerBackground">
        <div style={{ paddingTop: "85px" }}>
          <div className="container">
            <div className="heroBanner">
              <div className="bannerContents">
                <div style={{ paddingLeft: "100px" }}>
                  <h1 style={{ fontSize: "54px" }} className="heroTitle ftw700">
                    India’s Top <br />
                    Digital Rights <br />
                    Marketplace
                  </h1>
                  <p className="heroContent ftw400">
                    Buy - Sell - Secure any type of content easily
                  </p>
                </div>
                <div style={{ paddingRight: "50px" }}>
                  <img
                    src={tempPic}
                    alt=""
                    style={{ width: "400px", height: "390px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
