import React, { useState } from "react";
import shoppingCartImg from "../../assets/shoppingCart.png";
import { CiCircleCheck } from "react-icons/ci";

const sellPoints = [
  {
    id: 1,
    content: "Monitor all your digital rights in one place",
  },
  {
    id: 2,
    content: "Attract more Buyers",
  },
  {
    id: 3,
    content: "Buyers are fully verified",
  },
];

const SellBuyTab = () => {
  const [tab, setTab] = useState("SELL");
  return (
    <section>
      <div className="">
        <div className="container">
          <div className="tabOptions">
            <p
              className={`tabTextSell ${tab === "SELL" ? "activeTab" : ""}`}
              onClick={() => {
                setTab("SELL");
              }}
            >
              SELL
            </p>
            <p
              className={`tabTextBuy ${tab === "BUY" ? "activeTab" : ""}`}
              onClick={() => {
                setTab("BUY");
              }}
            >
              BUY
            </p>
          </div>

          <div className="tabContents">
            {tab === "SELL" && (
              <div>
                <p className="sellTitle">
                  Buy Movie Content Rights World Wide Without Complications
                </p>

                <div className="sellContents">
                  <div className="sellImgContainer">
                    <img
                      src={shoppingCartImg}
                      alt=""
                      style={{
                        width: "480px",
                        height: "360px",
                        padding: "20px",
                      }}
                    />
                  </div>
                  <div>
                    {sellPoints.map((item: any) => (
                      <p key={item.id} className="sellPoints">
                        <CiCircleCheck className="tickIcon" />
                        <span className="sellPointContents">
                          {item.content}
                        </span>
                      </p>
                    ))}

                    <button className="signUpBtnSell">Signup for Free</button>
                  </div>
                </div>
              </div>
            )}
            {tab === "BUY" && (
              <div>
                <p className="sellTitle">
                  Buy Movie Content Rights World Wide Without Complications
                </p>

                <div className="sellContents">
                  <div className="sellImgContainer">
                    <img
                      src={shoppingCartImg}
                      alt=""
                      style={{
                        width: "480px",
                        height: "360px",
                        padding: "20px",
                      }}
                    />
                  </div>
                  <div>
                    {sellPoints.map((item: any) => (
                      <p key={item.id} className="sellPoints">
                        <CiCircleCheck className="tickIcon" />
                        <span className="sellPointContents">
                          {item.content}
                        </span>
                      </p>
                    ))}

                    <button className="signUpBtnSell">Signup for Free</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SellBuyTab;
