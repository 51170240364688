import React, { useState } from "react";
import { movieStatusTypeMenu } from "../../utils/data";

const DistributionRelease = () => {
  const [movieStatus, setmovieStatus] = useState("Released");

  const handleChange = (event: any) => {
    setmovieStatus(event.target.value); // Update state on change
  };

  return (
    <div>
      <div>
        <p className="text-white ft18 ftw700">Distribution & Release</p>
      </div>

      <div style={{ marginTop: "35px" }}>
        <div>
          <p className="text-white ft16 ftw700">
            Movie Status
            <span className="mandatoryField">*</span>
          </p>
        </div>

        <div
          style={{
            marginTop: "30px",
            display: "flex",
            gap: "15%",
          }}
        >
          {movieStatusTypeMenu.map((item) => (
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
              }}
              key={item.id}
            >
              <input
                type="radio"
                id={item.title}
                name="contentType"
                value={item.title}
                checked={movieStatus === item.title}
                onChange={handleChange}
                className="custom-radio"
              />
              <label className="contentLabelName">{item.title}</label>
            </div>
          ))}
        </div>

        <div style={{ marginTop: "50px" }}>
          {movieStatus === "Released" && (
            <>
              <div className="gridContainerStyle">
                <div className="gridItemStyle">
                  <p className="contentLabelName">Release Date</p>
                  <input className="contentInput" />
                </div>
                <div className="gridItemStyle">
                  <p className="contentLabelName">Distributor Name</p>
                  <input className="contentInput" />
                </div>
                <div className="gridItemStyle">
                  <p className="contentLabelName">Distributor Company Name</p>
                  <input className="contentInput" />
                </div>
                <div className="gridItemStyle">
                  <p className="contentLabelName">
                    Distributor Contact Details
                  </p>
                  <input className="contentInput" />
                </div>
              </div>
            </>
          )}
          {movieStatus === "Un Released" && (
            <>
              <p className="contentLabelName">Expected Release Date</p>
              <input className="productionHouseName" type="text" />
            </>
          )}
          {movieStatus === "Under Production" && (
            <>
              <p className="contentLabelName">Pooja Date</p>
              <input className="productionHouseName" type="text" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DistributionRelease;
