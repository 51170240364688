import React from "react";
import img1 from "../../assets/indusTest.png";

const IndustryTesti = () => {
  const testimonialData = [
    {
      id: 1,
      name: "Ms. Supriya Yalagadda",
      designation: "Secretary, Active Film Producers Guild",
      content: `<p>“So far we all have been focusing on producing good content as we are basically filmmakers and we get exhausted to ready 30-page agreements. Now as a producer we have to focus on 360 degree monetization. Thank you GK and Vijay for opening up the box which we are passionate about but very scared to open it up all these days. Now we have Producerbazzar.com to track our rights when we focus on filmmaking.”</p>`,
      imgData: img1,
    },
    {
      id: 2,
      name: "Naren",
      designation: "Secretary, Active Film Producers Guild",
      content: `<p>“So far we all have been focusing on producing good content as we are basically filmmakers and we get exhausted to ready 30-page agreements. Now as a producer we have to focus on 360 degree monetization. Thank you GK and Vijay for opening up the box which we are passionate about but very scared to open it up all these days. Now we have Producerbazzar.com to track our rights when we focus on filmmaking.”</p>`,
      imgData: img1,
    },
    {
      id: 3,
      name: "Mohan",
      designation: "Secretary, Active Film Producers Guild",
      content: `<p>“So far we all have been focusing on producing good content as we are basically filmmakers and we get exhausted to ready 30-page agreements. Now as a producer we have to focus on 360 degree monetization. Thank you GK and Vijay for opening up the box which we are passionate about but very scared to open it up all these days. Now we have Producerbazzar.com to track our rights when we focus on filmmaking.”</p>`,
      imgData: img1,
    },
    {
      id: 4,
      name: "Moen",
      designation: "Secretary, Active Film Producers Guild",
      content: `<p>“So far we all have been focusing on producing good content as we are basically filmmakers and we get exhausted to ready 30-page agreements. Now as a producer we have to focus on 360 degree monetization. Thank you GK and Vijay for opening up the box which we are passionate about but very scared to open it up all these days. Now we have Producerbazzar.com to track our rights when we focus on filmmaking.”</p>`,
      imgData: img1,
    },
    {
      id: 5,
      name: "Ms. Supriya Yalagadda",
      designation: "Secretary, Active Film Producers Guild",
      content: `<p>“So far we all have been focusing on producing good content as we are basically filmmakers and we get exhausted to ready 30-page agreements. Now as a producer we have to focus on 360 degree monetization. Thank you GK and Vijay for opening up the box which we are passionate about but very scared to open it up all these days. Now we have Producerbazzar.com to track our rights when we focus on filmmaking.”</p>`,
      imgData: img1,
    },
  ];
  return (
    <section>
      <div className="">
        <div className="" style={{ paddingTop: "125px" }}>
          <div className="container">
            <div className="testiContainerDiv">
              <div
                style={{
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="testiLeft">
                  <img src={img1} alt="" className="testiImage" />
                  <p style={{ color: "white" }}>Ms. Supriya Yalagadda</p>
                  <p style={{ color: "white" }}>
                    Secretary, Active Film Producers Guild
                  </p>
                </div>
                <div className="testiRight">
                  <p className="industryTitle"> Industry Testimonials</p>
                  <p className="industryContent">
                    “So far we all have been focusing on producing good content
                    as we are basically filmmakers and we get exhausted to ready
                    30-page agreements. Now as a producer we have to focus on
                    360 degree monetization. Thank you GK and Vijay for opening
                    up the box which we are passionate about but very scared to
                    open it up all these days. Now we have Producerbazzar.com to
                    track our rights when we focus on filmmaking.”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustryTesti;
