import React from "react";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import "../styles/marketplace.css";
import { CiSearch } from "react-icons/ci";
import FilterItems from "../components/marketplace/FilterItems";
import { dealsData } from "../utils/data";

const MarketPlace = () => {
  return (
    <>
      <Header />
      <div>
        <div className="">
          <div className="container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "100px 0px",
                alignItems: "baseline",
                gap: "40px",
              }}
            >
              <div style={{ width: "30%", flexGrow: 1 }}>
                <div
                  style={{
                    background: "#1B0036",
                    borderRadius: "12px",
                    minHeight: "100vh",
                  }}
                >
                  <div className="filterTextContainer">
                    <p className="filterTitleText">Filters</p>
                  </div>

                  <FilterItems />
                </div>
              </div>

              <div style={{ width: "70%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    alignItems: "center",
                    gap: "40px",
                  }}
                >
                  <div>
                    <p className="marketPlaceTitle">Marketplace</p>
                  </div>

                  <div className="marketPlaceSearch">
                    <CiSearch className="searchIcon" />
                    <input placeholder="search" />
                  </div>
                </div>

                <div
                  style={{
                    paddingTop: "50px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "16px",
                  }}
                >
                  {dealsData.map((item: any) => (
                    <div
                      style={{
                        background: "#2F144B",
                        border: "1px solid #2F144B",
                        borderRadius: "12px",
                        padding: "12px",
                      }}
                      key={item.id}
                    >
                      <img src={item.pic} alt="" height={260} width={200} />
                      <p
                        style={{
                          color: "#999999",
                          fontSize: "14px",
                          paddingTop: "8px",
                        }}
                      >
                        {item.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MarketPlace;
