import React from "react";
import LanguageSelect from "./LanguageSelect";
import {
  crewTitleOptions,
  customStyles2,
  starringOptions,
} from "../../utils/data";
import Select from "react-select";
import { RiDeleteBin5Line } from "react-icons/ri";

const CastCrew = () => {
  const projectDetailsMap = [
    {
      id: 1,
      title: "Producer",
      name: "KL Narayana",
      project: "Baahubali",
    },
    {
      id: 2,
      title: "Co-Producer",
      name: "Jahnavi",
      project: "Baahubali",
    },
    {
      id: 3,
      title: "Director",
      name: "SS Rajamouli",
      project: "RRR",
    },
  ];
  return (
    <div>
      <div>
        <p className="text-white ft18 ftw700">Cast & Crew</p>
      </div>

      <div style={{ marginTop: "35px" }}>
        <div>
          <p className="contentLabelName">
            Starring<span className="mandatoryField">*</span>
          </p>

          <LanguageSelect
            options={starringOptions}
            defaultValue={[starringOptions[0], starringOptions[1]]}
          />
        </div>

        <div style={{ marginTop: "35px" }}>
          <p className="text-white ft16 ftw700">Crew Details</p>

          <div
            className="gridContainerStyle"
            style={{ gridTemplateRows: "none" }}
          >
            <div className="gridItemStyle">
              <p className="contentLabelName">Title</p>
              <Select
                options={crewTitleOptions}
                styles={customStyles2}
                className="crewSelect"
                defaultValue={crewTitleOptions[0]}
              />
            </div>
            <div className="gridItemStyle">
              <p className="contentLabelName">Name</p>
              <input className="contentInput" placeholder="" />
            </div>
          </div>
        </div>

        <div style={{ marginTop: "25px" }}>
          <p className="contentLabelName">Previous Project Details</p>
          <div style={{ display: "flex", gap: "10px", marginTop: "15px" }}>
            <input
              className="productionHouseName"
              placeholder=""
              style={{ marginTop: "0" }}
            />
            <button className="addButton">Add</button>
          </div>
        </div>

        <div style={{ marginTop: "50px" }}>
          <div className="crewTable headerRow">
            {["S.No", "Title", "Name", "Previous Project Details"].map(
              (item: any, index: number) => (
                <p key={index} className="contentLabelName">
                  {item}
                </p>
              )
            )}
          </div>
          {projectDetailsMap.map((item: any) => (
            <div className="crewTableContent" key={item.id}>
              <p className="crewTableValue">{item.id}</p>
              <p className="crewTableValue">{item.title}</p>
              <p className="crewTableValue">{item.name}</p>
              <p
                className="crewTableValue"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {item.project}
                <RiDeleteBin5Line className="crewTableValueIcon" />
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CastCrew;
