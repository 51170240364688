import React from "react";
import Select from "react-select";
import { CallingCodes, customStyles2 } from "../../utils/data";

const ProducerInformation = () => {
  const callingCodeOptions = CallingCodes.map(({ country, value, code }) => ({
    country,
    value,
    code,
    label: `${value}`,
  }));

  return (
    <div>
      <div>
        <p className="text-white ft18 ftw700">Producer Information</p>
      </div>

      <div style={{ marginTop: "35px" }}>
        <div>
          <p className="contentLabelName">
            Production House Name
            <span className="mandatoryField">*</span>
          </p>
          <input className="productionHouseName" />
        </div>

        <div className="gridContainerStyle">
          <div className="gridItemStyle">
            <p className="contentLabelName">
              Producer First Name
              <span className="mandatoryField">*</span>
            </p>
            <input className="contentInput" />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">
              Producer Last Name
              <span className="mandatoryField">*</span>
            </p>
            <input className="contentInput" />
          </div>
          <div className="gridItemStyle">
            {" "}
            <p className="contentLabelName">
              Phone
              <span className="mandatoryField">*</span>
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                marginTop: "12px",
                gap: "5px",
              }}
            >
              <Select
                options={callingCodeOptions}
                styles={customStyles2}
                defaultValue={[callingCodeOptions[0]]}
                className="phoneNumberDropdown"
              />
              <input className="contentInputPhone" type="number" />
            </div>
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">
              Email
              <span className="mandatoryField">*</span>
            </p>
            <input className="contentInput" />
          </div>
        </div>
        <div style={{ marginTop: "25px" }}>
          <p className="contentLabelName">
            Address
            <span className="mandatoryField">*</span>
          </p>
          <textarea className="synopsisTextArea" />
        </div>
      </div>
    </div>
  );
};

export default ProducerInformation;
