import React, { useState } from "react";

const MultivaluesSelection = ({ data }: any) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  // Handle checkbox change
  const handleCheckboxChange = (id: number) => {
    setSelectedItems(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((item) => item !== id) // Deselect the item
          : [...prevSelected, id] // Select the item
    );
  };

  console.log("check", selectedItems);
  return (
    <div>
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        {data.map((type: any) => (
          <div key={type.id} style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              id={`checkbox-${type.id}`}
              value={type.item}
              checked={selectedItems.includes(type.id)}
              onChange={() => handleCheckboxChange(type.id)}
              style={{ display: "none" }}
            />
            <label
              htmlFor={`checkbox-${type.id}`}
              style={{
                padding: "5px 14px",
                cursor: "pointer",
                backgroundColor: selectedItems.includes(type.id)
                  ? "#4F1787"
                  : "#1B0036",
                color: "white",
                borderRadius: "5px",
                fontSize: "14px",
                display: "inline-block",
              }}
            >
              {type.item}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultivaluesSelection;
