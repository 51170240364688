import React, { useState } from "react";
import { copyRightTypes, movieGenreTypes } from "../../utils/data";
import { Toggle } from "rsuite";

interface MovieGenreType {
  name: string;
}

const SynopsisGenre = () => {
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  const [copyRightValues, setCopyRightValues] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState(false);

  const handleGenreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    if (checked) {
      setSelectedGenres([...selectedGenres, value]);
    } else {
      setSelectedGenres(selectedGenres.filter((genre) => genre !== value));
    }
  };

  const handleCopyRightChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = event.target;

    if (checked) {
      setCopyRightValues([...copyRightValues, value]);
    } else {
      setCopyRightValues(copyRightValues.filter((item) => item !== value));
    }
  };

  const handleToggleChange = (value: boolean) => {
    setIsChecked(value);
  };

  console.log("ischecked", isChecked);

  return (
    <div>
      <div>
        <p className="text-white ft18 ftw700">
          Synopsis & Genre
          <span className="mandatoryField">*</span>
        </p>
      </div>

      <div style={{ marginTop: "30px" }}>
        <div>
          <p className="contentLabelName">Synopsis</p>
          <textarea className="synopsisTextArea" />
        </div>

        <div style={{ marginTop: "35px" }}>
          <p className="text-white ft16 ftw700">
            Movie Genre
            <span className="mandatoryField">*</span>
          </p>

          <div className="synopsis-grid-container">
            {movieGenreTypes.map((item: MovieGenreType) => (
              <div className="synopsis-grid-item">
                <input
                  type="checkbox"
                  id={item.name}
                  name="genre"
                  value={item.name}
                  checked={selectedGenres.includes(item.name)} // Set checked state based on selectedGenres
                  onChange={handleGenreChange}
                />
                <label htmlFor={item.name}> {item.name}</label>
              </div>
            ))}
          </div>
        </div>

        <div style={{ marginTop: "50px" }}>
          <div style={{ display: "flex", gap: "25px" }}>
            <p className="text-white ft16 ftw700">
              Registered Copyright under IPR ACT
              <span className="mandatoryField">*</span>
            </p>
            <p>
              <Toggle checked={isChecked} onChange={handleToggleChange} />
            </p>
          </div>

          <div className="synopsis-grid-container">
            {copyRightTypes.map((item: MovieGenreType) => (
              <div
                className={`${
                  isChecked ? "synopsis-grid-item" : "disabledCopy"
                }`}
              >
                <input
                  type="checkbox"
                  id={item.name}
                  name="copyright"
                  value={item.name}
                  checked={copyRightValues.includes(item.name)} // Set checked state based on selectedGenres
                  onChange={handleCopyRightChange}
                  className={`${isChecked ? "" : "disabledCheck"}`}
                />
                <label
                  htmlFor={item.name}
                  className={`${isChecked ? "" : "disabledCheckLabel"}`}
                >
                  {" "}
                  {item.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SynopsisGenre;
