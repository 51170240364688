import React, { useState } from "react";
import LanguageSelect from "./LanguageSelect";

const FeatureFlim = ({ title }: any) => {
  const [selectedValue, setSelectedValue] = useState<string>("Yes");

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
  };
  return (
    <div>
      <div>
        <p className="ft18 ftw700 text-white">{title}</p>
        <div className="gridContainerStyle">
          <div className="gridItemStyle">
            <p className="contentLabelName">
              Content Title
              <span className="mandatoryField">*</span>
            </p>
            <input className="contentInput" />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">Category</p>
            <input className="contentInput" placeholder="Type to add" />
          </div>
          <div className="gridItemStyle">
            <p className="contentLabelName">
              Original Language<span className="mandatoryField">*</span>
            </p>
            <input className="contentInput" />
          </div>
          <div className="gridItemStyle">
            {" "}
            <p className="contentLabelName">
              Available Audio Languages
              <span className="mandatoryField">*</span>
            </p>
            <div>
              <LanguageSelect />
            </div>
          </div>
          <div className="gridItemStyle">
            {" "}
            <p className="contentLabelName">Title Registrations Certificate</p>
            <div
              style={{
                backgroundColor: "#2F144B",
                height: "46px",
                marginTop: "12px",
                borderRadius: "5px",
                display: "flex",
                padding: "0px 2px",
                position: "relative",
              }}
            >
              <input
                type="file"
                className=""
                style={{ color: "white", padding: "10px" }}
              />

              <button className="uploadBtn">Upload</button>
            </div>
          </div>
          <div className="gridItemStyle">
            {" "}
            <p className="contentLabelName">
              Available Subtitles
              <span className="mandatoryField">*</span>
            </p>
            <LanguageSelect />
          </div>
          <div className="gridItemStyle">
            {" "}
            <p className="contentLabelName">
              Trademark For Title Registered
              <span className="mandatoryField">*</span>
            </p>
            <div style={{ display: "flex", gap: "25px", paddingTop: "25px" }}>
              {["Yes", "No"].map((item: any) => (
                <div
                  key={item}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    name="myRadio"
                    value={item}
                    className="custom-radio"
                    id={item}
                    onChange={handleRadioChange}
                    checked={selectedValue === item}
                  />
                  <label
                    htmlFor={item}
                    className="contentLabelName"
                    style={{ paddingLeft: "10px" }}
                  >
                    {item}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureFlim;
