import React, { useState } from "react";
import { masterRightTypes } from "../../utils/data";

interface MovieGenreType {
  name: string;
}

const RightsAvailable = () => {
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);

  const handleGenreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    if (checked) {
      setSelectedGenres([...selectedGenres, value]);
    } else {
      setSelectedGenres(selectedGenres.filter((genre) => genre !== value));
    }
  };
  return (
    <div>
      <div>
        <p className="text-white ft18 ftw700">Rights Available</p>
      </div>

      {masterRightTypes.map((ele: any) => (
        <div className="masterRightDiv">
          <p className="text-white ft16 ftw700">
            {ele.rightType}
            {ele.isMandatory && <span className="mandatoryField">*</span>}
          </p>

          <div className="rights-grid-container">
            {ele.options.map((item: MovieGenreType) => (
              <div className="synopsis-grid-item">
                <input
                  type="checkbox"
                  id={item.name}
                  name="genre"
                  value={item.name}
                  checked={selectedGenres.includes(item.name)} // Set checked state based on selectedGenres
                  onChange={handleGenreChange}
                />
                <label htmlFor={item.name}> {item.name}</label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RightsAvailable;
