import React from "react";
import "../../styles/footer.css";
import { FaFacebook } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const footerSocial = [
  {
    id: 1,
    link: "https://www.facebook.com/producerbazaarofficial?mibextid=LQQJ4d&rdid=aVNBGEndcfOeX10o&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FL8qLLhFj6xmPyhLJ%2F%3Fmibextid%3DLQQJ4d",
    icon: <FaFacebook />,
  },
  {
    id: 2,
    link: "https://x.com/producerbazaar",
    icon: <RiTwitterXFill />,
  },
  {
    id: 3,
    link: "https://www.linkedin.com/company/producerbazaarofficial/",
    icon: <FaLinkedin />,
  },
  {
    id: 4,
    link: "https://www.youtube.com/@producerbazaar4202",
    icon: <FaYoutube />,
  },
  {
    id: 5,
    link: "https://www.instagram.com/producerbazaar/",
    icon: <FaInstagram />,
  },
];

const Footer = () => {
  return (
    <footer
      style={{
        width: "100%",
        backgroundColor: "#2F005D",
      }}
    >
      <div className="container">
        <div>
          <div className="fooContentContainer">
            <div>
              <p className="footerTitle">Producer Bazaar</p>
              <p className="footerText">
                Unlocking the Value of Film Rights, Driving <br /> Transparent
                Monetization for Years to Come.
              </p>
            </div>
            <div className="footerLinks">
              <div>
                <li>Marketplace</li>
                <li>Sell</li>
                <li>Buy</li>
              </div>
              <div>
                <li>About us</li>
                <li>Blog</li>
                <li>Contact us</li>
              </div>
              <div>
                <li>Login</li>
                <li>Support</li>
                <li>Privacy</li>
              </div>
            </div>
            <div>
              <p className="connectTitle">Connect With Us</p>

              <div className="socialLinksContainer">
                {footerSocial.map((item: any) => (
                  <a
                    style={{ padding: "12px" }}
                    key={item.id}
                    className="socialLinks"
                    href={item.link}
                    target="blank"
                  >
                    <span className="socialIcons">{item.icon}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="footerCopyRight">
            <div>
              <p>© ProducerBazaar 2024. All Rights Reserved.</p>
            </div>
            <div style={{ display: "flex", gap: "40px" }}>
              <p>Terms of Use</p>
              <p>Cookie Policy</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
