import React, { useState } from "react";
import FourFieldLayout from "./FourFieldLayout";
import SixFieldLayout from "./SixFieldLayout";
import Events from "./Events";
import MusicAlbums from "./MusicAlbums";
import FeatureFlim from "./FeatureFlim";
import { contentTypeMenu } from "../../utils/data";

const ContentType = () => {
  const [selectedContent, setSelectedContent] = useState("Feature Flim");

  const handleChange = (event: any) => {
    setSelectedContent(event.target.value); // Update state on change
  };

  const renderContentLayout = () => {
    switch (selectedContent) {
      case "Documentary":
        return <FourFieldLayout title={"Documentary"} />;
      case "Feature Flim":
        return <FeatureFlim title={"Feature Flim"} />;
      case "Events & Live Shows":
        return <Events title={"Events & Live Shows"} />;
      case "Short Flim":
        return <FourFieldLayout title={"Short Flim"} />;
      case "Music Albums":
        return <MusicAlbums title={"Music Albums"} />;
      case "Television Series":
        return (
          <SixFieldLayout
            title={"Television Series"}
            episodeLabel={"Total Episodes"}
          />
        );
      case "Web Series":
        return (
          <SixFieldLayout title={"Web Series"} episodeLabel={"No Of Episode"} />
        );
      // Add other cases for different layouts
      default:
        return <div>Please select a content type</div>;
    }
  };
  return (
    <div>
      <div>
        <p className="text-white ft18 ftw700">Content Type</p>
      </div>

      <div
        style={{
          marginTop: "30px",
          height: "90px",
          paddingBottom: "30px",
          borderBottom: "1px dotted #2F144B",
        }}
        className="rights-grid-container"
      >
        {contentTypeMenu.map((item) => (
          <div
            style={{
              alignItems: "center",
            }}
            className="synopsis-grid-item"
            key={item.id}
          >
            <input
              type="radio"
              id={item.title}
              name="contentType"
              value={item.title}
              checked={selectedContent === item.title}
              onChange={handleChange}
              className="custom-radio"
            />
            <label className="contentLabelName">{item.title}</label>
          </div>
        ))}
      </div>

      <div style={{ marginTop: "30px", marginBottom: "30px" }}>
        {renderContentLayout()}
      </div>
    </div>
  );
};

export default ContentType;
