import React from "react";
import logo from "../../assets/Group.png";
import { PiThumbsUp } from "react-icons/pi";
import "../../styles/header.css";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const router = useNavigate();
  const navItems = [
    {
      id: 1,
      name: "Home",
      link: "/",
    },
    {
      id: 2,
      name: "Market Place",
      link: "/market-place",
    },
    {
      id: 3,
      name: "Sell",
      link: "/sell",
    },
    {
      id: 4,
      name: "Buy",
      link: "",
    },
  ];

  return (
    <div>
      <div className="headerBackground">
        <div className="container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "200px", height: "80px", cursor: "pointer" }}
              onClick={() => router("/")}
            >
              <img
                src={logo}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </div>
            <ul
              style={{
                display: "flex",
                gap: "40px",
                color: "white",
                listStyle: "none",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {navItems.map((item: any) => (
                <Link
                  to={item.link}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li key={item.id} className="ft16">
                    {item.name}
                  </li>
                </Link>
              ))}
              <p className="dealsBtn ft16">
                {" "}
                <PiThumbsUp style={{ color: "#ffffff", fontSize: "18px" }} />
                <span>Deals</span>
              </p>
              <p
                className="loginBtn ft14 ftw500"
                onClick={() => router("/sign-in")}
              >
                Login
              </p>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
